/* 
The below group of selectors is a CSS reset to improve consistency and control.
Partly used code from: http://meyerweb.com/eric/tools/css/reset/
for this reset.
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}

body {
    color: white;
	background: hsla(0, 0%, 8%, 100%);
	font-family: univers;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'univers';
    src: url('./univers-condensed-medium.otf');
}

.container {
    padding: 20px;
    max-width: 1024px;
    margin: auto;
}

.departureBoard {
    display: flex;
    flex-direction: column;
}

.departure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

/* Style the labels for Linje, Endestation and Tid. */
.legend {
    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 10px;
}


.singleDisplay {
    display: flex;
}

.digit {
    position: relative;
    min-width: 22px;
    max-width: 22px;
    height: 32px;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden;
	background: hsla(0, 0%, 12%, 100%);
    color: white;
    font-size: 32px;
    margin-left: 3px;
    line-height: 32px;
    text-transform: uppercase;
    border-radius: 0px;
    box-shadow: inset 0px 1px 1px hsla(0, 0%, 0%, 80%),
                inset 0px -1px 1px hsla(0, 0%, 0%, 97%);
}

/* Add a line across the digits, to simulate solari board effect. */
.digit::before {
    border-top: 1px solid hsla(0, 0%, 0%, 40%);
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
}

.digit:first-child {
    margin-left: 0px;
}

.linje {
    display: block;
    min-width: 72px;
}

.station {
    display: block;
    min-width: 697px;
}

.tid {
    display: block;
    min-width: 122px;
    text-align: right;
}

/* Optimize display for mobile devices. */
@media only screen and (max-width: 960px) {

    .departure {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: calc((100vw - 40px) / 17.18);
    }
    
    .singleDisplay {
        display: flex;
    }

    .singleDisplay:nth-last-child(-n+2) {
        margin-top: calc(((100vw) - (100%)) / 24);
    }

    .singleDisplay:nth-child(2) .digit:nth-last-child(-n+3) { /* Cut of the last three digits on mobile to make space for larger letters. */
        display: none;
    }

    .singleDisplay:nth-child(2) {
        justify-content: space-between;
    }

    /* Don't display the traditional legend on mobile. */
    .legend {
        display: none;
    }

    .digit {
        min-width: calc(((100vw - 40px) / 25) - ((100vw) - (100%)) / 24);
        max-width: calc(((100vw - 40px) / 25) - ((100vw) - (100%)) / 24);
        height: calc((100vw - 40px) / 17.18);
        font-size: calc((100vw - 40px) / 17.18);
        margin-left: calc(((100vw) - (100%)) / 24); /* 25-1 */
        line-height: calc(100vw / (17.18 + 1));
        box-shadow: inset 0px calc(100vw * 0.001) calc(100vw * 0.002) hsla(0, 0%, 0%, 80%),
                    inset 0px calc(100vw * 0.001 * -1) calc(100vw * 0.002) hsla(0, 0%, 0%, 97%);
    }

    .digit::before {
        border-top: calc(100vw * 0.0015) solid hsla(0, 0%, 0%, 40%);
    }
}